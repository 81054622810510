import { useState, useEffect } from "react";
import AxiosInstance from "../Axios";
import { Card } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import DocsForm from "./DocsForm";
import BasicModal from 'src/components/Modal';
import Button from 'react-bootstrap/Button';
import { CButton } from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import { confirmAlert } from "react-confirm-alert";
import { BsSortNumericDown } from 'react-icons/bs';
import { sortObj } from "../store/sortObj";
import { FaFilePdf, FaRegFileAlt } from "react-icons/fa";
import { BiHide } from "react-icons/bi";
import { GrView } from "react-icons/gr";



const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

const DocsList = ({ agreement_id, account_id,  filter }) => {

    const [pageInfos, setPageInfos] = useState([]);
    const [showModalItemUpdate, setShowModalItemUpdate] = useState(false);
    const [showModalItemNew, setShowModalItemNew] = useState(false);
    const [selectedData, setSelectedData] = useState(null); // Вибраний модуль для редагування
    const popup = MyToastify();

    const actionShowModal = (rez) => {
        setShowModalItemNew(rez);
    };

    const actionShowModalItemNew = (rez) => {
        setShowModalItemNew(rez);
        if (!rez) {
            // console.log("actionShowModalItemNew");
            getItems();
        }
    };

    // const actionShowModalItemUpdate2 = (rez) => {
    //     setShowModalItemUpdate(rez);
    // };

    const actionShowModalItemUpdate = (rez) => {
        setShowModalItemUpdate(rez);
        if (!rez) {
            getItems();
            setSelectedData(rez);
        };
    };

    const getItems = () => {
        let param = { [filter]: agreement_id };
        AxiosInstance.get(`/documents`, { params: param }).then((response) => {
            // console.log('/page_infos get response', response);
            if (response.status == 200)
                setPageInfos(response.data['hydra:member']);
        });
    };

    useEffect(() => {
        if (agreement_id != undefined) {
            getItems();
        };
    }, [agreement_id]);

    function Deleted(id) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        const response = AxiosInstance.delete('/documents/' + id, header).then((response) => {
                            getItems();
                        });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    // Відкривати вибраний модуль для редагування
    useEffect(() => {
        if (selectedData) {
            setShowModalItemUpdate(true);
        };
    }, [selectedData]);

    return (
        <Card className="mt-5" >
            <Card.Header>Документи</Card.Header>
            <Card.Body>

                {( pageInfos != undefined) && pageInfos.map((page) => {
                    // console.log('page', page);
                    return (
                        <Card key={page.id} body className="info-list">
                            {/* <BasicModal
                                show={showModalItemUpdate}
                                // actionShowModal={actionShowModalItemUpdate2}
                                actionShowModal={actionShowModalItemUpdate}
                                title={`Оновити модуль`}
                                btn_name={page.key}
                                btn_color="light"
                                content={
                                    <PageInfoForm
                                        // getItems={getItems}
                                        toastifySuccess={popup.toastifySuccess} parent_id={id} actionShowModal={actionShowModalItemUpdate} data={page} type={type} />
                                }
                            /> */}
                            <div onClick={() => setSelectedData(page)} style={{ cursor: "pointer" }} title="Редагувати">
                                    {(page.media.contentUrl.includes(".pdf"))?
                                        <><FaFilePdf />  </>
                                        :
                                        <><FaRegFileAlt /></>
                                    }
                                <strong>{page.type}</strong><br/>
                                <span>{process.env.REACT_APP_SERVER_URL + page.media.contentUrl}</span>
                                <p>{page.description}</p>
                                <p>{(page.documentsViews != undefined && page.documentsViews.length > 0)? <><GrView /> {page.documentsViews[0].dateEntered}</>:<BiHide />}</p>
                            </div>
                            <div>
                                <CButton color="danger" variant="outline" onClick={() => { Deleted(page.id) }}>
                                    <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                                </CButton>
                                {/* <small className='SortPunkt'><BsSortNumericDown /> - {page.sort}</small> */}
                            </div>
                        </Card>
                    );
                })}


                <hr />
                <BasicModal
                    show={showModalItemNew}
                    // actionShowModal={actionShowModalItemNew}
                    actionShowModal={actionShowModal}
                    title={`Додати модуль`}
                    btn_name={<>+ додати</>}
                    content={
                        <DocsForm
                            // getItems={getItems}
                            toastifySuccess={popup.toastifySuccess} account_id={account_id} agreement_id={agreement_id} actionShowModal={actionShowModalItemNew} filter={filter} />
                    }
                />
                {/* Модальне вікно для редагування модуля */}
                    {(selectedData && showModalItemUpdate) &&
                    <div style={{display: 'none'}}> {/* приховати кнопку відкритого модуля */}
                        <BasicModal
                            show={showModalItemUpdate}
                            actionShowModal={actionShowModalItemUpdate}
                            title={`Оновити модуль`}
                            btn_name={selectedData.key}
                            btn_color="light"
                            content={
                                <DocsForm
                                    // getItems={getItems}
                                    toastifySuccess={popup.toastifySuccess}  account_id={account_id} agreement_id={agreement_id}  actionShowModal={actionShowModalItemUpdate} data={selectedData} />
                            }
                        />
                    </div>
                }
            </Card.Body>

        </Card>
    );
}


export default DocsList;