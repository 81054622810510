import { useEffect, useState } from "react";
import AxiosInstance from "../Axios";
import Table from 'react-bootstrap/Table';
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DocsList from 'src/components/docs/DocsList';
import MonthYearSelect from "./MonthYearSelect";


const ActScore = ({account_id, agreement_id, user_id}) => {

    const [rowsAct, setRowsAct] = useState([]);
    const [date, setDate] = useState(false);
    const [dateCheck, setDateCheck] = useState(false);
    const [data, setData] = useState({'акт': false, 'рахунок': false});
    const [act, setAct] = useState(false);
    const [score, setScore] = useState(false);
    const userData = JSON.parse(localStorage.getItem('user_data'));

    // Запит за контрагентами (+фільтр)
    const getRowsAct = (type, p = 1) => {
        let request = {'type': type};
        
        if(date !== false){
            let selectedDate = new Date(date);

            // Знайти останній день місяця
            let lastDayOfMonth = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth() + 1, // Перейти на наступний місяць
                1 // 0-й день наступного місяця — це останній день поточного
            );
            request['date[after]'] = date;
            request['date[before]'] =  lastDayOfMonth.toISOString().split('T')[0]; 
        }
        if(account_id == undefined && agreement_id == undefined && user_id == undefined)
            request['exists[account]'] =  false;
        if(account_id != undefined)
            request['account.id'] = account_id;
        if(agreement_id != undefined)
            request['agreement.id'] = agreement_id;
        if(user_id != undefined)
            request['account.user.id'] = user_id;
        // console.log("request", request);
        AxiosInstance.get(`/documents?page=${p}`, {params: request}).then((response) => {

            if(type == 'акт')
                setAct( response.data['hydra:member'] );
            if(type == 'рахунок')
                setScore( response.data['hydra:member'] );

            // setRowsAct(response.data['hydra:member']);
        });
    };
    useEffect(() => {
        getRowsAct('акт')
        getRowsAct('рахунок')
    }, [])    


    const datePicker = (value, name) => {
       console.log(value);
       let formattedDate = value.toISOString().slice(0, 10);
       console.log(formattedDate);
        setDate(formattedDate)
    }

    useEffect(() => {
        console.log(dateCheck);
        if(dateCheck.year == 'all' || dateCheck.month == 'all'){
            setDate(false);
        }else{
            if(dateCheck.year !== undefined && dateCheck.month !== undefined && dateCheck.year !== false && dateCheck.month !== false)
                setDate(`${dateCheck.year}-${dateCheck.month}-01`);
        }
    }, [dateCheck]);

    useEffect(() => {
        console.log(date);
        getRowsAct('акт')
        getRowsAct('рахунок')
    }, [date]);


    useEffect(() => {
        if(act !== false && score !== false ){
            // console.log(data);
            const mergedArray = [...act, ...score];

            // Сортуємо по id
            mergedArray.sort((a, b) => a.id - b.id);

            setRowsAct(mergedArray);
        }        
    }, [act, score]);

    function VieDoc(url, id){
        AxiosInstance.post('/documents_views', {
            "view": true,
            "viewUser": "/api/users/" + userData.id,
            "document": "/api/documents/" + id
        }).then((responce) => {
            window.location = url;
        }).catch((e) => {

        });
    }

    return(
        <>
             <Table striped>
                <thead>
                    <tr>
                        <th>Розрахунковий період</th>
                        <th>Тип</th>
                        <th>Документ</th>
                        <th>Опис</th>
                    </tr>
                    <tr>
                        <th>
                        {/* <DatePicker
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                            // selected={(values.dateSigning == null)? new Date(): new Date(values.dateSigning)}
                            onChange={(date) => datePicker(date, 'dateSigning')}
                        /> */}
                        <MonthYearSelect setDate={setDateCheck} />
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rowsAct.map((m) => {


                            return(
                                <tr key={`message_${m.id}`}>
                                    <th>{dayjs(m.date).format('DD.MM.YYYY')}</th>
                                    <th>{m.type}</th>
                                    <td><a  target="_blanck" onClick={() => VieDoc(process.env.REACT_APP_SERVER_URL + m.media.contentUrl, m.id)}>Сформувати</a></td>
                                    <td>{m.description}</td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
             </Table>
            
        </>
    );
}

export default ActScore;