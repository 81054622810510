import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton,   CFormSelect} from '@coreui/react';
import Select from 'react-select'
import BasicModal from 'src/components/Modal';
import MediaFrom from 'src/components/media/MediaFrom';
import PermissionsNoPage, { Permissions } from "src/components/Permissions";
import Card from 'react-bootstrap/Card';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DocsList from 'src/components/docs/DocsList';



const urlApi = '/agreements';
const urlRouter = '/agreements';
const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));


export async function LoaderAgreementUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}


const AgreementsForm = ({ user_data }) => {

  if (!Permissions(user_data, 'Agreements', 'EDIT_AGREEMENTS')) {
    return <PermissionsNoPage />;
  }
  const popup = MyToastify();
  const navigate = useNavigate();
  const { data } = useLoaderData();

  const [values, setValues] = useState({
    name: (data != undefined )? data.name: '',
    number: (data != undefined )? data.number: '',
    dateEntered: (data != undefined )? data.dateEntered: null,
    dateEnd: (data != undefined )? data.dateEnd: null,
    type: (data != undefined )? data.type: '',
    region: (data != undefined )? data.region: '',
    status: (data != undefined )? data.status: '',
    iban: (data != undefined )? data.iban: '',
    numberClient: (data != undefined )? data.numberClient: '',
    description: (data != undefined )? data.description: '',
    dateSigning: (data != undefined )? data.dateSigning: null,
    account: (data != undefined && data.account != undefined )? data.account.id: '',
  })
  const [selectItems, setSelectItems] = useState({
    account: {},
    // status: status[0],
});

  useEffect(() => {
    if(data != null){
        setSelectItems({
            ...selectItems,
            account: {
                label: data.account.name,
                value: data.account.id,
            },
            // status: s,
           
        });
    }
  }, []);


  const [myData, setMyData] = useState(data ? data : false);
  
  useEffect(() => {
    if (data) {
      setMyData(data);
    }
  }, [data]);

  useEffect(() => {

  }, []);

  
  const datePicker = (value, name) => {
    let formattedDate = value.toISOString().slice(0, 10);
    console.log(typeof formattedDate);
    // values['dateOrder'] = formattedDate;
    setValues({
        ...values,
        [name]: formattedDate
    });
    console.log(values);
}



  // Функція для відправлення запиту
    const handleSubmit = async (event) => {
        event.preventDefault();
        let val = values;
        // setValues({
        //     ...values,
        //     ['account']: (typeof values.account != 'string')? `/api/accounts/${values.account}`: values.account
        // });
        val['account'] = (typeof values.account != 'string')? `/api/accounts/${values.account}`: values.account
    
        if (data == undefined) {
            const response = await AxiosInstance.post(urlApi, val).then((responce) => {

                popup.toastifySuccess('Користувача створено!');
                navigate(`/agreements/update/${responce.data.id}`);
            });
            
        } else {
            const response = await AxiosInstance.put(`${urlApi}/${data.id}`, val).then((responce) => {
                popup.toastifySuccess('Користувача оновлено!');
                navigate(`/agreements/update/${responce.data.id}`);
            });
        };
    };


    const handleChange = (value, name) => {
        
        setValues({
        ...values,
        [name]: value.value
        });
        setSelectItems({
        ...selectItems,
        [name]: value
        });
    };

    const handleInputChange = (e) => {
        console.log(e);
        const { name, value, type } = e.target;
        setValues({
          ...values,
          [name]: type === "number" ? parseFloat(value, 10) : value
        });
      };


     /** Контрагенти */
     const [selectedOption, setSelectedOption] = useState(null);
     const loadOptionsAcc = async (inputValue) => {
         return await AxiosInstance.get('/accounts?name=' + inputValue, { headers: { 'accept': 'application/json' } }).then((response) => {
             return response.data.map((result) => ({
                 label: result.name,
                 value: result.id,
             }));
         });
     }; 

  return (
    <>
      <ToastContainer />
      

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Номер</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={values.number} name="number" onChange={handleInputChange} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput6">Номер Договору</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput6" value={values.numberClient} name="numberClient" onChange={handleInputChange} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Дата Підписання</CFormLabel>
          <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd"
                selected={(values.dateSigning == null)? new Date(): new Date(values.dateSigning)}
                onChange={(date) => datePicker(date, 'dateSigning')}
            />
        </div>
        
       
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput3">Діє до</CFormLabel>
          <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd"
                selected={(values.dateEnd == null)? new Date(): new Date(values.dateEnd)}
                onChange={(date) => datePicker(date, 'dateEnd')}
            />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput4">Тип</CFormLabel>
          <CFormSelect
            aria-label="Default select example"
            onChange={handleInputChange}
            defaultValue={values.type}
            name="type"
            options={[
              { label: 'Тендерний', value: 'Тендерний' },
              { label: 'Не тендерний', value: 'Не тендерний' },
            ]}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput4">Область\Регіон</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput4" value={values.region}  name="region" onChange={handleInputChange} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Контрагент</CFormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptionsAcc}
                            // defaultOptions
                            value={selectItems.account}
                            // onChange={handleChange}
                            className="flex-grow-1"
                            name="account"
                            // defaultOptions={values.account} 
                            onChange={(value) =>{ handleChange(value, 'account')} }
                            classNamePrefix='account'
                            styles={{border:'1px solid red'}}
                            
                        />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput6">IBAN</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput6" value={values.iban} name="iban" onChange={handleInputChange} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput7">коментар</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput7" value={values.description} name="description" onChange={handleInputChange} autoComplete="off" />
        </div>
        

        <CButton type="submit" color="success">Зберегти</CButton>
       
      </CForm>

      {(data != undefined)&&
        <DocsList agreement_id={data.id} account_id={data.account.id} filter="agreement.id" />
      }
    </>
  );
};

export default AgreementsForm;