import { useEffect, useState } from "react";
import AxiosInstance from "../Axios";
import Table from 'react-bootstrap/Table';
import dayjs from 'dayjs';
import MonthYearSelect from "./MonthYearSelect";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";




const HistoryPower = ({account_id, agreement_id, user_id}) => {

    const [rowsMessage, setRowsMessage] = useState([]);
    const [dateCheck, setDateCheck] = useState(false);
    const [date, setDate] = useState(false);

    // Запит за контрагентами (+фільтр)
    const getRowsMessage = (p = 1) => {
        let request = {};
        if(date !== false){
            let selectedDate = new Date(date);

            // Знайти останній день місяця
            let lastDayOfMonth = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth() + 1, // Перейти на наступний місяць
                1 // 0-й день наступного місяця — це останній день поточного
            );
            request['date[after]'] = date;
            request['date[before]'] =  lastDayOfMonth.toISOString().split('T')[0]; 
        }
        if(account_id != undefined)
            request['agreement.account.id'] = account_id;
        if(agreement_id != undefined)
            request['agreement.id'] = agreement_id;
        if(user_id != undefined)
            request['agreement.account.user.id'] = user_id;
        // console.log("request", request);
        AxiosInstance.get(`/history_powers?page=${p}`, {params: request}).then((response) => {
            setRowsMessage(response.data['hydra:member']);
        });
    };
    useEffect(() => {
        getRowsMessage()
    }, [])    

    function downloadCsvFile() {
        // URL до вашого контролера
        const url = `/history_powers/export`;
    
        // Запит через Axios
        AxiosInstance.get(url,{
            responseType: 'blob', // Важливо: щоб отримати файл у вигляді blob
            headers: {
                'Accept': 'text/csv', // Вказуємо, що очікуємо CSV
            },
        })
            .then((response) => {
                // Створення URL з Blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'history_power.csv'); // Ім'я файлу
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    }
    useEffect(() => {
        console.log(dateCheck);
        if(dateCheck.year == 'all' || dateCheck.month == 'all'){
            setDate(false);
        }else{
            if(dateCheck.year !== undefined && dateCheck.month !== undefined && dateCheck.year !== false && dateCheck.month !== false)
                setDate(`${dateCheck.year}-${dateCheck.month}-01`);
        }
    }, [dateCheck]);

    useEffect(() => {
        console.log(date);
        getRowsMessage()
        
    }, [date]);
    // Виклик функції для тесту
    // downloadCsvFile(7, '2024-01-01', '2024-11-18');

    /** https://api-cabinet-etzahid.inneti.net/api/history_powers/export */
    return(
        <>

        
             <Table striped>
                <thead>
                    <tr>
                        <th>
                        {/* <DatePicker
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                            // selected={(values.dateSigning == null)? new Date(): new Date(values.dateSigning)}
                            onChange={(date) => datePicker(date, 'dateSigning')}
                        /> */}
                        <MonthYearSelect setDate={setDateCheck} />
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th className="d-flex justify-content-end">
                            <Button onClick={downloadCsvFile} variant="light"><FaDownload /> Експортувати</Button>
                        </th>
                    </tr>
                    <tr>
                        <th>Дата</th>
                        <th>Споживання, кВт*год</th>
                        <th>Вартість</th>
                        <th>Вартість з ПДВ</th>
                        <th>Номер Договору/Контрагент</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rowsMessage.map((m) => {


                            return(
                                <tr key={`hiss_${m.id}`}>
                                    <th>{dayjs(m.date).format('DD.MM.YYYY')}</th>
                                    <td>{m.count}</td>
                                    <td>{m.sum}</td>
                                    <td>{m.sumPdv}</td>
                                    <td>{m.agreement.number}</td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
             </Table>
            
        </>
    );
}

export default HistoryPower;